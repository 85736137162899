<template>
  <v-autocomplete
    v-model="facility"
    v-bind="$attrs"
    :items="$store.state.selects.facilities"
    :loading="loading"
    :search-input.sync="search"
    :label="$tc('facility', multiple ? 2 : 1) | capitalize"
    cache-items
    dense
    :multiple="multiple"
    :small-chips="multiple"
    :clearable="!$attrs.readonly"
    menu-props="offsetY"
    item-text="name"
    item-value="uuid"
    @click:clear="uuid = null; fetchFacilities"
    @change="$emit('update:value', facility)"
  >
    <template #prepend-inner>
      <v-icon
        small
        class="mt-1"
        color="grey"
      >
        fal fa-hospital
      </v-icon>
    </template>
    <template #selection="{ item }">
      <v-chip
        x-small
        class="ml-0"
        v-text="item.name"
      />
    </template>
  </v-autocomplete>
</template>
<script>
  export default {
    props: {
      value: {
        type: [Array, Object, String],
        default: () => [],
      },
      params: {
        type: Object,
        default: () => ({}),
      },
      multiple: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        loading: false,
        search: null,
        uuid: null,
        facility: this.value,
      }
    },
    watch: {
      search () {
        this.fetchFacilities()
      },
      value () {
        this.facility = this.value
      },
    },
    created () {
      if (this.value.length && typeof this.value === 'string' && !this.$attrs.readonly) {
        this.uuid = this.value
      }
      if (this.$store.state.selects.facilities.length === 0 || !this.$store.state.selects.facilities.map(x => x.uuid).includes(this.value)) {
        this.fetchFacilities()
      }
    },
    methods: {
      fetchFacilities () {
        this.loading = true
        const promise = this.axios.get('facilities', {
          params: {
            ...this.params,
            ...{
              fields: ['uuid','name'],
              quick: true,
              search: this.search,
              uuid: this.uuid,
              sort: 'name',
            },
          },
        })
        return promise.then((response) => {
          this.$store.state.selects.facilities = response.data.data.filter(f => f.uuid.length > 0 && f.name.length > 0)
        }).catch(error => {
          this.$toast.error(error.response?.data?.message)
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
